<template>
  <div class="container-fluid" id="content" :style="cssStyle">
    <div class="row mx-auto h-100">
      <!-- Imgsets -->
      <div class="col-lg-10 overflow-auto h-100" id="imgset_creation">
        <!-- <div class="row w-100 mx-auto pb-2" id = "imgset_creation_title">
          <button
            class="btn btn-dark"
            data-bs-toggle="collapse"
            data-bs-target="#imgset_creation_content"
            aria-expanded="true"
            aria-controls="imgset_creation_content">
            <h4 class="mt-1">Image Sets &#9776;</h4>
          </button>
        </div> -->
        <!-- Create Image Sets -->
        <div class="show collapse row w-100 mx-auto" id = "imgset_creation_content">
          <!-- Display warning if the study already started (results are present)
                                 User can still modify design but should be aware that this can cause bugs
                                -->
          <div id="imgset" class="mx-auto px-0 w-100">
            <!--Images -->
              <DicomViewerTools :toolsMousekeys="toolsMousekeys" :toolsMousewheel="toolsMousewheel" class="sticky-top mb-2"></DicomViewerTools>
              <div v-if="refviewerNumb" class="badge bg-secondary mx-auto w-100">
                <h6 class="">Reference-Image-Stack(s)</h6>
              </div>
              <div id="ref-stacks" :class="refviewerLayout">
                <div v-for="index in refviewerNumb" :key="index">
                  <h6>Reference Image Viewer {{index}}</h6>
                <dicom-viewer viewer-type="refviewers" :viewer-index="index-1"></dicom-viewer>
                </div>
              </div>
              <div v-if="refviewerNumb"  class="badge bg-secondary w-100 mb-2">
                <h6 class="">Image-Stack(s)</h6>
              </div>
              <div id="stacks" :class="viewerLayout">
                <div v-for="index in viewerNumb" :key="index">
                  <h6>Image Viewer {{index}}</h6>
                  <dicom-viewer viewer-type="viewers" :viewer-index="index-1"></dicom-viewer>
                </div>
              </div>
            <div>
          </div>
          </div>
        </div>
      </div>
      <!-- sidebar for design, viewport settings, scales etc (rigth) -->
      <div class="col-lg-2 overflow-auto sticky-top h-100" id="sidebar">
        <!-- Design Settings -->
        <DesignOptions></DesignOptions>
        <ImgsetDesign class="w-100"></ImgsetDesign>
      </div>
    </div>
  </div>
</template>

<script>
import DicomViewer from '@/components/dicomViewer/DicomViewer.vue'
import DicomViewerTools from '@/components/dicomViewer/DicomViewerTools.vue'
import DesignOptions from '@/components/studyDesign/DesignOptions.vue'
import ImgsetDesign from '@/components/studyDesign/ImgsetDesign.vue'
import { tools } from '@/components/dicomViewer/tools'

export default {
  name: 'StudyDesign',
  components: {
    DicomViewer,
    DicomViewerTools,
    ImgsetDesign,
    DesignOptions
  },
  computed: {
    viewerNumb () {
      return this.$store.getters['currentStudy/viewerNumb']
    },
    viewerLayout () {
      // var colClass = 'grid-cols-' + this.$store.getters.viewerLayoutCols
      // var rowClass = 'grid-rows-' + this.$store.getters.viewerLayoutRows
      var gridClass = {
        'tw-flex': true,
        'tw-relative': true,
        'tw-grid': true,
        'tw-grid-cols-5': this.$store.getters['currentStudy/viewerLayoutCols'] === 5,
        'tw-grid-cols-4': this.$store.getters['currentStudy/viewerLayoutCols'] === 4,
        'tw-grid-cols-3': this.$store.getters['currentStudy/viewerLayoutCols'] === 3,
        'tw-grid-cols-2': this.$store.getters['currentStudy/viewerLayoutCols'] === 2,
        'tw-grid-cols-1': this.$store.getters['currentStudy/viewerLayoutCols'] === 1
      }
      return gridClass
    },
    refviewerNumb () {
      return this.$store.getters['currentStudy/refviewerNumb']
    },
    refviewerLayout () {
      var gridClass = {
        'tw-flex': true,
        'tw-relative': true,
        'tw-grid': true,
        'tw-gap-2': true,
        'tw-grid-cols-1': this.$store.getters['currentStudy/refviewerNumb'] === 1,
        'tw-grid-cols-2': this.$store.getters['currentStudy/refviewerNumb'] === 2,
        'tw-grid-cols-3': this.$store.getters['currentStudy/refviewerNumb'] === 3,
        'tw-grid-cols-4': this.$store.getters['currentStudy/refviewerNumb'] === 4,
        'tw-grid-cols-5': this.$store.getters['currentStudy/refviewerNumb'] === 5,
        'tw-grid-rows-1': true
      }
      return gridClass
    },
    cssStyle () {
      return {
        'background-color': this.$store.getters['currentStudy/backgroundColor'],
        color: this.$store.getters['currentStudy/textColor']
      }
    },
    toolsMousekeys () {
      return tools.toolsMousekeys
    },
    toolsMousewheel () {
      return tools.toolsMousewheel
    }
  },
  watch: {
  },
  mounted () {
  },
  activated () {
    this.$store.commit('currentStudy/imgsetDisplayed', undefined)
  },
  deactivated () {
  },
  methods: {
  }
}
</script>

<style>
.primary-accordion {
  background: #212529 !important;
  color: white !important;
}

.primary-accordion.collapsed::after {
  background-image: url("");
}

.primary-accordion:not(.collapsed)::after {
  background-image: url("") !important;
}

.bg-gray-300 {
  background: #adb5bd !important;
}
</style>
