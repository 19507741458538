<template>
  <div class="container">
      <div class="row mx-auto my-2">
          <button class="btn btn-secondary btn-block" data-toggle="collapse" data-target="#basic_tutorials"
                  aria-expanded="false" aria-controls="basic_tutorials">
              <h5 class="col-12 mt-1">Basics</h5>
          </button>
      </div>
      <div id="basic_tutorials" class="collapse show">
          <div class="row mx-auto mb-2">
              <div class="col-4 show mt-2">
                  <span class="badge badge-light btn-block">
                      <h4 class="col-12 mt-1">Creating Studies</h4>
                  </span>
              </div>
              <div class="col-4 show mt-2" id="basic_tutorials">
                  <span class="badge badge-light btn-block">
                      <h4 class="col-12 mt-1">User Types</h4>
                  </span>
              </div>
          </div>

          <div class="row mx-auto mb-2">
              <div class="col-4" controls>
                  <iframe src="https://player.vimeo.com/video/569591003" class="w-100"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-4" controls>
                  <iframe src="https://player.vimeo.com/video/545645634" class="w-100"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
          </div>
      </div>
      <div class="row mx-auto mb-2">
          <button  class="btn btn-secondary btn-block" data-toggle="collapse" data-target="#study_example_tutorials"
                  aria-expanded="false" aria-controls="study_example_tutorials">
              <h5 class="col-12 mt-1">Study Tutorials</h5>
          </button>
      </div>
      <div id="study_example_tutorials" class="collapse show">
          <div class="row mx-auto mb-2">
              <div class="col-4 show mt-2">
                  <span class="badge badge-light btn-block">
                      <h4 class="col-12 mt-1">AFC tutorial</h4>
                  </span>
              </div>
              <div class="col-4 show mt-2" id="basic_tutorials">
                  <span class="badge badge-light btn-block">
                      <h4 class="col-12 mt-1">ROC tutorial</h4>
                  </span>
              </div>
              <div class="col-4 show mt-2" id="basic_tutorials">
                  <span class="badge badge-light btn-block">
                      <h4 class="col-12 mt-1">LROC and FROC tutorial</h4>
                  </span>
              </div>
          </div>

          <div class="row mx-auto">
              <div class="col-4" controls>
                  <iframe src="https://player.vimeo.com/video/569585723" class="w-100"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-4" controls>
                  <iframe src="https://player.vimeo.com/video/569587208" class="w-100"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="col-4" controls>
                  <iframe src="https://player.vimeo.com/video/569587617" class="w-100"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "HowTo"
}
</script>

<style>

</style>
