<template>
    <!-- image and mask selector -->
    <div class='flex items-center row-span-1 col-span-1 animate-fade-in-up'>
        <div class="input-group mx-auto" data-toggle="tooltip" data-placement="left"
          title="">
            <label class="input-group-text w-35">Viewer {{this.viewerIndex + 1}}</label>
            <select ref='image_select_id'
                class='form-select'
                v-model="stackDisplayed">
                <option :value="{ cs_stack: { currentImageIdIndex: Number, imageIds: [] }, name: String}"></option>
                <option v-for='stack in stacks' :key='stack.name' :value="{ stack_id: stack.stack_id, cs_stack: stack.cs_stack, name: stack.name }">
                    {{ stack.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    viewerIndex: Number,
    viewerType: String
  },
  computed: {
    stacks () {
      return this.$store.getters['currentStudy/stacks']
    },
    stackDisplayed: {
      get () {
        const stackDisplayed = this.$store.getters['imageViewers/stackDisplayed'](this.viewerIndex, this.viewerType)
        if (stackDisplayed) {
          return { cs_stack: stackDisplayed.csStack, name: stackDisplayed.name, stack_id: stackDisplayed.stack_id }
        } else {
          return undefined
        }
      },
      set (stack) {
        this.$store.commit('imageViewers/stackDisplayed',
          {
            stack_id: stack.stack_id,
            name: stack.name,
            stackDisplayed: stack.cs_stack,
            index: this.viewerIndex,
            viewertype: this.viewerType
          }
        )
      }
    }
  }
}
</script>

<style>

</style>
